import { render, staticRenderFns } from "./AdminNavbar.vue?vue&type=template&id=1a4df78f&scoped=true&name=component-name"
var script = {}
import style0 from "./AdminNavbar.vue?vue&type=style&index=0&id=1a4df78f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a4df78f",
  null
  
)

export default component.exports